<template>
  <div>
    <!-- 单程 -->
    <div v-if="newItem && newItem.tours.length == 1" class="wrap">
      <div class="top tour-content">
        <div>
          <div class="tour-content-flight">
            <span>{{newItem.tours[0].depCityName}} - {{newItem.tours[0].arrCityName}}</span>
          </div>
          <div class="tour-content-time">
            <div class="filter">
              <p class="filter-title">航班号</p>
              <p class="filter-value">{{newItem.tours[0].flightNo}}</p>
            </div>
            <div class="filter">
              <p class="filter-title">出发时间</p>
              <p
                class="filter-value"
              >{{newItem.tours[0].flightDate}} {{newItem.tours[0].takeOffTime}}</p>
            </div>
          </div>
        </div>
        <div
          v-if="getCheckinState(newItem.tours[0]) == 1"
          class="btn"
          style="cursor: pointer;"
          @click="toChooseSeat(newItem.tours[0],0)"
        >值机</div>
        <div v-else-if="getCheckinState(newItem.tours[0]) == 2" class="seats">
          <span class="read"></span>
          <span class="seatNo">已选座位：{{newItem.tours[0].asrSeat}}</span>
          <div
            class="cancel"
            style="cursor: pointer;margin-left:20px;"
            @click="beforetoCancelSeat(newItem.tours[0],0)"
            v-if="newItem.tours[0].isFlightOpened == 'OP' || newItem.tours[0].isFlightOpened == ''"
          >取消值机</div>
        </div>
        <div v-else-if="getCheckinState(newItem.tours[0]) == 3" class="unable">本次航班不支持值机</div>
      </div>
    </div>
    <div v-if="newItem && newItem.tours.length == 2" class="wrap">
      <div class="top tour-content">
        <div>
          <div class="tour-content-flight">
            <span>{{newItem.tours[0].depCityName}} - {{newItem.tours[0].arrCityName}}</span>
          </div>
          <div class="tour-content-time">
            <div class="filter">
              <p class="filter-title">航班号</p>
              <p class="filter-value">{{newItem.tours[0].flightNo}}</p>
            </div>
            <div class="filter">
              <p class="filter-title">出发时间</p>
              <p
                class="filter-value"
              >{{newItem.tours[0].flightDate}} {{newItem.tours[0].takeOffTime}}</p>
            </div>
          </div>
        </div>
        <div
          v-if="getCheckinState(newItem.tours[0]) == 1"
          class="btn"
          style="cursor: pointer;"
          @click="toChooseSeat(newItem.tours[0],0)"
        >值机</div>
        <div v-else-if="getCheckinState(newItem.tours[0]) == 2" class="seats">
          <span class="read"></span>
          <span class="seatNo">已选座位：{{newItem.tours[0].asrSeat}}</span>
          <div
            class="cancel"
            style="cursor: pointer;margin-left:20px;"
            @click="beforetoCancelSeat(newItem.tours[0],0)"
            v-if="newItem.tours[0].isFlightOpened == 'OP' || newItem.tours[0].isFlightOpened == ''"
          >取消值机</div>
        </div>
        <div v-else-if="getCheckinState(newItem.tours[0]) == 3" class="unable">本次航班不支持值机</div>
      </div>
      <div class="top tour-content">
        <div>
          <div class="tour-content-flight">
            <span>{{newItem.tours[1].depCityName}} - {{newItem.tours[1].arrCityName}}</span>
          </div>
          <div class="tour-content-time">
            <div class="filter">
              <p class="filter-title">航班号</p>
              <p class="filter-value">{{newItem.tours[1].flightNo}}</p>
            </div>
            <div class="filter">
              <p class="filter-title">出发时间</p>
              <p
                class="filter-value"
              >{{newItem.tours[1].flightDate}} {{newItem.tours[1].takeOffTime}}</p>
            </div>
          </div>
        </div>
        <div
          v-if="getCheckinState(newItem.tours[1]) == 1"
          class="btn"
          style="cursor: pointer;"
          @click="toChooseSeat(newItem.tours[1],1)"
        >值机</div>
        <div v-else-if="getCheckinState(newItem.tours[1]) == 2" class="seats">
          <span class="read"></span>
          <span class="seatNo">已选座位：{{newItem.tours[1].asrSeat}}</span>
          <div
            class="cancel"
            style="cursor: pointer;margin-left:20px;"
            @click="beforetoCancelSeat(newItem.tours[1],0)"
            v-if="newItem.tours[1].isFlightOpened == 'OP' || newItem.tours[1].isFlightOpened == ''"
          >取消值机</div>
        </div>
        <div v-else-if="getCheckinState(newItem.tours[1]) == 3" class="unable">本次航班不支持值机</div>
      </div>
    </div>
    <!-- <div v-if="newItem && newItem.tours.length == 2" class="goBackWrap">
      <div class="wrap">
        <div class="goBackTop tour-content">
          <div class="iconWrap">
            <img src="../../../assets/checkin-go.png">
          </div>
          <div class="contentWrap">
            <div class="row">
              <span class="citys">
                {{newItem.tours[0].depCityName}} -
                {{newItem.tours[0].arrCityName}}
              </span>
              <span class="flightNo">航班号：{{newItem.tours[0].flightNo}}</span>
            </div>
            <p>出发时间：{{newItem.tours[0].flightDate}} {{newItem.tours[0].takeOffTime}}</p>
          </div>
        </div>
        <div
          v-if="getCheckinState(newItem.tours[0]) == 1"
          style="cursor: pointer;"
          class="btn"
          @click="toChooseSeat(newItem.tours[0],0)"
        >值机</div>
        <div v-else-if="getCheckinState(newItem.tours[0]) == 2" class="seats">
          <span class="read"></span>
          <span class="seatNo">已选座位：{{newItem.tours[0].asrSeat}}</span>
          <div
            class="cancel"
            style="cursor: pointer;"
            @click="beforetoCancelSeat(newItem.tours[0],0)"
            v-if="newItem.tours[0].isFlightOpened == 'OP' || newItem.tours[0].isFlightOpened == ''"
          >取消值机</div>
        </div>
        <div v-else-if="getCheckinState(newItem.tours[0]) == 3" class="unable">本次航班不支持值机</div>
      </div>
      <div class="wrap">
        <div class="goBackTop">
          <div class="iconWrap">
            <img src="../../../assets/checkin-back.png">
          </div>
          <div class="contentWrap">
            <div class="row">
              <span class="citys">
                {{newItem.tours[1].depCityName}} -
                {{newItem.tours[1].arrCityName}}
              </span>
              <span class="flightNo">航班号：{{newItem.tours[1].flightNo}}</span>
            </div>
            <p>出发时间：{{newItem.tours[1].flightDate}} {{newItem.tours[1].takeOffTime}}</p>
          </div>
        </div>
        <div class="bottom">
          <div
            v-if="getCheckinState(newItem.tours[1]) == 1"
            style="cursor: pointer;"
            class="btn"
            @click="toChooseSeat(newItem.tours[1],1)"
          >值机</div>
          <div v-else-if="getCheckinState(newItem.tours[1]) == 2" class="seats">
            <span class="read"></span>
            <span class="seatNo">已选座位：{{newItem.tours[1].asrSeat}}</span>
            <div
              class="cancel"
              style="cursor: pointer;"
              @click="beforetoCancelSeat(newItem.tours[1],1)"
              v-if="newItem.tours[1].isFlightOpened == 'OP' || newItem.tours[1].isFlightOpened == ''"
            >取消值机</div>
          </div>
          <div v-else-if="getCheckinState(newItem.tours[1]) == 3" class="unable">本次航班不支持值机</div>
        </div>
      </div>
    </div> -->
    <!-- <confirm title="您确定要取消值机吗？" confirmText='确认取消' cancelText="取消" :show="checkinConfirm" @on-confirm="toCancelSeat()" @on-cancel="checkinConfirm = false">
    </confirm>-->
  </div>
</template>

<script>
import {
  fetchCheckinFlightList,
  cancelCheckin,
  fetchCheckinQuery,
  fetchCheckinSeats
} from "@/api/newsapi";
export default {
  props: {
    item: {
      type: Object
    }
  },
  components: {},
  data() {
    return {
      newItem: null,
      status: 1,
      checkinConfirm: false,
      tours: null,
      index: null
    };
  },
  watch: {
    item() {
      this.newItem = this.item;
    }
  },
  beforeMount() {
    this.newItem = this.item;
    this.$emit("loadingEvent", true);
    let count = 0;
    for (let i = 0; i < this.newItem.tours.length; i++) {
      let temp = this.newItem.tours[i];
      let params = {
        dptAptCode: temp.depAirportCode,
        arvAptCode: temp.arrAirportCode,
        flightDate: temp.flightDate,
        flightNo: temp.flightNo,
        ticketNo: this.newItem.ticketNo
      };
      fetchCheckinQuery(params)
        .then(res => {
          count++;
          if (count == this.newItem.tours.length)
            this.$emit("loadingEvent", false);
          if (res.success) {
            let tour = this.newItem.tours[i];
            tour.success = true;
            tour.pstCkiStatus = res.data.pstCkiStatus;
            tour.asrSeat = res.data.asrSeat;
            tour.isFlightOpened = res.data.isFlightOpened;
            tour.tips = res.data.tips;
            tour.boardingGateNumber = res.data.boardingGateNumber;
            this.$set(this.newItem.tours, i, tour);
          } else {
            let tour = this.newItem.tours[i];
            tour.success = false;
            this.$set(this.newItem.tours, i, tour);
          }
        })
        .catch(error => {
          this.$emit("loadingEvent", false);
          this.$emit("errorEvent", "系统异常");
        });
    }
  },
  methods: {
    beforetoCancelSeat(tour, index) {
      console.log(tour);
      console.log(index);
      this.tours = tour;
      this.index = index;
      this.checkinConfirm = true;
      let _this = this;
      this.$confirm("确认要取消吗？")
        .then(_ => {
          _this.toCancelSeat();
        })
        .catch(_ => {});
    },
    clone(obj) {
      var o;
      if (typeof obj == "object") {
        if (obj === null) {
          o = null;
        } else {
          if (obj instanceof Array) {
            o = [];
            for (var i = 0, len = obj.length; i < len; i++) {
              o.push(this.clone(obj[i]));
            }
          } else {
            o = {};
            for (var j in obj) {
              o[j] = this.clone(obj[j]);
            }
          }
        }
      } else {
        o = obj;
      }
      return o;
    },
    toChooseSeat(tour, index) {
      if (!tour.success) {
        let params = {
          dptAptCode: tour.depAirportCode,
          arvAptCode: tour.arrAirportCode,
          flightDate: tour.flightDate,
          flightNo: tour.flightNo,
          ticketNo: this.newItem.ticketNo
        };
        this.$emit("loadingEvent", true);
        fetchCheckinQuery(params)
          .then(res => {
            this.$emit("loadingEvent", false);
            if (res.success) {
              let temp = tour;
              temp.success = true;
              temp.pstCkiStatus = res.data.pstCkiStatus;
              temp.asrSeat = res.data.asrSeat;
              temp.isFlightOpened = res.data.isFlightOpened;
              temp.tips = res.data.tips;
              temp.boardingGateNumber = res.data.boardingGateNumber;
              this.$set(this.newItem.tours, index, temp);
              if (this.getCheckinState(temp) == 1) {
                this.getSeats(temp);
              } else if (this.getCheckinState(temp) == 2) {
                this.$emit(
                  "errorEvent",
                  `该行程已选座位：${temp.asrSeat}，可返回重新查询`
                );
              } else if (this.getCheckinState(temp) == 3) {
                this.$emit("errorEvent", "系统暂不支持本航班值机");
              } else {
                this.$emit("errorEvent", "系统异常");
              }
            } else {
              if (res.errorCode === 'A60511003001') {
                this.$alert(res.errorMessage, '值机失败', {
                  confirmButtonText: '我知道了',
                });
                return;
              }
              let temp = tour;
              temp.success = false;
              this.$set(this.newItem.tours, index, temp);
              this.$emit("errorEvent", res.errorMessage);
            }
          })
          .catch(error => {
            this.$emit("loadingEvent", false);
            this.$emit("errorEvent", "系统异常");
          });
      } else {
        this.getSeats(tour);
      }
    },
    toCancelSeat(tour = this.tours) {
      console.log(tour);
      this.checkinConfirm = false;
      let _this = this;
      let userInput = JSON.parse(
        localStorage.getItem("airtravel-checkin-userInput")
      );
      let params = {
        certNo: userInput.cert,
        certType: userInput.certType,
        passengerName: userInput.name,
        mobile: userInput.phone,
        flightNo: tour.flightNo,
        flightDate: tour.flightDate,
        takeOffTime: tour.takeOffTime,
        depAirportCode: tour.depAirportCode,
        arrAirportCode: tour.arrAirportCode,
        cabinClass: tour.cabinClass,
        seatNo: tour.asrSeat,
        ticketNo: this.newItem.ticketNo,
        tourIndex: tour.tourIndex
      };
      this.$emit("loadingEvent", true);
      cancelCheckin(params)
        .then(res => {
          this.$emit("loadingEvent", false);
          if (res.success) {
            for (let i = 0; i < _this.newItem.tours.length; i++) {
              if (_this.newItem.tours[i].tourIndex == tour.tourIndex) {
                _this.newItem.tours[i].pstCkiStatus = "NA";
              }
            }
          } else {
            // _this.$router.push({
            //   name: "checkinFail",
            //   params: {
            //     prepage: "cancel"
            //   }
            // });
            this.$emit(
              "errorEvent",
              `取消值机失败，请重试或返回原值机渠道取消！`
            );
          }
        })
        .catch(err => {
          this.$emit("loadingEvent", false);
          this.$emit("errorEvent", "取消值机失败，请重试或返回原值机渠道取消！");
        });
    },
    getCheckinState(item) {
      // 1 显示值机按钮
      // 2 显示已选座位和取消按钮
      // 3 显示“系统暂不支持本航班值机”
      if (
        item.success == false ||
        (item.isFlightOpened == "OP" && item.pstCkiStatus == "DL") ||
        ((item.isFlightOpened == "OP" || item.isFlightOpened == "") &&
          item.pstCkiStatus == "NA") ||
        (item.isFlightOpened == "" && item.pstCkiStatus == "")
      ) {
        return 1;
      } else if (item.pstCkiStatus == "AC" || item.pstCkiStatus == "PS") {
        return 2;
      } else if (
        ((item.isFlightOpened != "OP" || item.isFlightOpened != "") &&
          item.pstCkiStatus == "NA") ||
        (item.pstCkiStatus == "DL" || item.pstCkiStatus == "SB")
      ) {
        return 3;
      }
    },
    getSeats(item) {
      let params = {
        flightDate: item.flightDate,
        flightNo: item.flightNo,
        depAirportCode: item.depAirportCode,
        arrAirportCode: item.arrAirportCode,
        cabinClass: item.cabinClass
      };
      this.$emit("loadingEvent", true);
      fetchCheckinSeats(params)
        .then(res => {
          this.$emit("loadingEvent", false);
          if (res.success) {
            let journey = this.clone(this.newItem);
            journey.tours = item;
            localStorage.setItem(
              "airtravel-checkin-journey",
              JSON.stringify(journey)
            );
            localStorage.setItem("airtravel-checkin-seats", res.data.seatMap);
            this.$router.push({
              name: "checkin_seatmap"
            });
          } else {
            this.$emit("errorEvent", res.errorMessage);
          }
        })
        .catch(error => {
          this.$emit("errorEvent", "系统异常");
        });
    }
  }
};
</script>
<style lang="less" scoped>
.wrap {
  height: 122px;
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 10px;
}

.goBackWrap {
  height: 243px;
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  padding: 0 14px;
  margin-bottom: 10px;

  .wrap {
    position: relative;
    padding: 0;
    margin-bottom: 0;

    &:nth-last-child(n + 2):after {
      content: "";
      position: absolute;
      width: 200%;
      height: 1px;
      bottom: 0;
      border-bottom: 1px dashed #dcdcdc;
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: scale(0.5, 0.5);
      transform: scale(0.5, 0.5);
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
}

.top {
  position: relative;
  height: 55px;

  &:after {
    content: "";
    position: absolute;
    width: 200%;
    height: 1px;
    bottom: 0;
    border-bottom: 1px dashed #dcdcdc;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scale(0.5, 0.5);
    transform: scale(0.5, 0.5);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.goBackTop {
  position: relative;
  height: 75px;
}

.iconWrap {
  width: 23px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 17px;

  img {
    width: 15px;
    height: 15px;
  }
}

.contentWrap {
  margin-left: 23px;
}

.row {
  padding: 15px 0 4px;
  width: 100%;
  display: flex;
  line-height: 22px;

  .citys {
    font-size: 16px;
    color: #333;
    display: block;
    flex: 2;
  }

  .deptDate {
    font-size: 12px;
    color: #333;
    display: block;
    flex: 2;
  }

  .flightNo {
    font-size: 12px;
    color: #333;
    width: 100px;
    margin-right: 10px;
    text-align: right;
  }
}

p {
  font-size: 12px;
  color: #666;
}

.seats {
  position: absolute;
  right: 20px;
  .read {
    display: inline-block;
    margin-right: 6px;
    width: 15px;
    height: 15px;
    background: url("../../../assets/read.png") no-repeat;
    background-size: contain;
    vertical-align: middle;
  }

  .seatNo {
    margin-top: 16px;
    display: inline-block;
    color: #d70039;
    font-size: 12px;
    line-height: 15px;
  }

  .cancel {
    float: right;
    width: 80px;
    height: 27px;
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    text-align: center;
    color: #333;
    font-size: 14px;
    line-height: 27px;
    margin-top: 10px;
  }
}

.unable {
  font-size: 14px;
  color: #ea323a;
  text-align: center;
  line-height: 47px;
  position: absolute;
  right: 20px;
}

.tour-content {
  font-size: 14px !important;
  display: flex;
  align-items: center;
  //   width: 100%;
  padding: 20px;
  position: relative;
  &-time {
    display: flex;
    margin-top: 10px;
    div {
      margin-right: 20px;
    }
  }
  .btn {
    width: 60px;
    padding: 5px;
    background-color: #d70039 !important;
    color: #ffffff;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    height: 20px;
    position: absolute;
    right: 20px;
    font-size: 14px;
  }
}
</style>